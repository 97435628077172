import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as Store from '../store/Store';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


interface Match {
    id: string | undefined;
    FIDSoftware: string | undefined;
}

type SoftwareVersionsProps =
    Store.StoreState &
    typeof Store.actionCreators &
    RouteComponentProps<Match>;

interface IRowData {
    dataIndex: number;
    index: number;
}
interface IRow {
    data: IRowData[];
}


const columns: MUIDataTableColumnDef[] = [
    { name: "Version", label: 'Versione' },
    { name: "ReleaseNotes", label: 'Note rilascio' },
    
];
const SoftwareVersions = (props: SoftwareVersionsProps) => {
    useEffect(() => {
        if (props.match.params.FIDSoftware) {
            let FIDSoftware: number = parseInt(props.match.params.FIDSoftware);
            props.requestSoftwareVersionList(FIDSoftware);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.requestSoftwareList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDelete]);
    const onEditSoftwareVersion = (id: number) => () => {
        props.history.push(`/softwareVersion/edit/${props.match.params.FIDSoftware}/${id}`);
    }
    const onNewSoftwareVersion = () => {
        props.history.push(`/softwareVersion/create/${props.match.params.FIDSoftware}`);
    }
    const onDeleteSoftwareVersion = (row: IRow) => () => {
        var { softwareVersions } = props;
        for (var i in row.data) {
            var data: IRowData = row.data[i];
            var softwareVersion: Store.SoftwareVersion = softwareVersions[data.dataIndex];
            props.deleteSoftwareVersion(softwareVersion.ID);
        }
    }
    const customToolbar = () => {
        return <React.Fragment>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwareVersions || false)) && <Tooltip title="Nuovo">
                <IconButton onClick={onNewSoftwareVersion}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </React.Fragment>;
    }
    const customToolbarSelect = (selectedRows: IRow) => {
        var { softwareVersions } = props;
        var data: IRowData = selectedRows.data[0];
        var softwareVersion: Store.SoftwareVersion = softwareVersions[data.dataIndex];
        return <div>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwareVersions || false)) && selectedRows.data.length === 1 && <Tooltip title="Modifica">
                <IconButton onClick={onEditSoftwareVersion(softwareVersion.ID)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.DeleteSoftwareVersions || false)) && <Tooltip title="Elimina">
                <IconButton onClick={onDeleteSoftwareVersion(selectedRows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>}
        </div>;
    }

    if (props.isLoading) {
        return <div>Loading</div>;
    }

    return <div>
        <MUIDataTable
            title="Versioni software"
            data={props.softwareVersions}
            columns={columns}
            options={{
                filterType: "checkbox",
                download: false,
                onRowsDelete: onDeleteSoftwareVersion,
                print: false,
                viewColumns: false,
                filter: false,
                selectableRowsHeader: props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwareVersions || false) || (props.CurrentUser?.Permissions.DeleteSoftwareVersions || false),
                isRowSelectable: () => props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwareVersions || false) || (props.CurrentUser?.Permissions.DeleteSoftwareVersions || false),
                customToolbar: customToolbar,
                customToolbarSelect: customToolbarSelect
            }}
        />
    </div>;
}

export default connect(
    (state: ApplicationState) => state.store,
    Store.actionCreators
)(SoftwareVersions as any);
