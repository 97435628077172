import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as Store from '../store/Store';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

type UserProps =
    Store.StoreState &
    typeof Store.actionCreators &
    RouteComponentProps<{}>;

interface IRowData {
    dataIndex: number;
    index: number;
}
interface IRow {
    data: IRowData[];
}


const columns: MUIDataTableColumnDef[] = [
    { name: "Name", label: 'Nome' },
    { name: "Username", label: 'Username' },
    {
        name: "IsAdmin", label: 'Admin', options: {
            customBodyRender: (value: boolean) => (value ? "SI" : "NO")
        }
    }
];
const Users = (props: UserProps) => {
    useEffect(() => {
        props.requestUserList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.requestUserList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDelete]);
    const onEditUser = (id: number) => () => {
        props.history.push(`/user/edit/${id}`);
    }
    const onNewUser = () => {
        props.history.push(`/user/create`);
    }
    const onDeleteUser = (row: IRow) => () => {
        var { users } = props;
        for (var i in row.data) {
            var data: IRowData = row.data[i];
            var user: Store.User = users[data.dataIndex];
            props.deleteUser(user.ID);
        }
    }
    const customToolbar = () => {
        return <React.Fragment>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateUsers || false)) && <Tooltip title="Nuovo">
                <IconButton onClick={onNewUser}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </React.Fragment>;
    }
    const customToolbarSelect = (selectedRows: IRow) => {
        var { users } = props;
        var data: IRowData = selectedRows.data[0];
        var user: Store.User = users[data.dataIndex];
        return <div>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateUsers || false)) && selectedRows.data.length === 1 && <Tooltip title="Modifica">
                <IconButton onClick={onEditUser(user.ID)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.DeleteUsers || false)) && <Tooltip title="Elimina">
                <IconButton onClick={onDeleteUser(selectedRows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>}
        </div>;
    }

    if (props.isLoading) {
        return <div>Loading</div>;
    }

    return <div>
        <MUIDataTable
            title="Users"
            data={props.users}
            columns={columns}
            options={{
                filterType: "checkbox",
                download: false,
                onRowsDelete: onDeleteUser,
                print: false,
                viewColumns: false,
                filter: false,
                selectableRowsHeader: props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateUsers || false) || (props.CurrentUser?.Permissions.DeleteUsers || false),
                isRowSelectable: () => props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateUsers || false) || (props.CurrentUser?.Permissions.DeleteUsers || false),
                customToolbar: customToolbar,
                customToolbarSelect: customToolbarSelect
            }}
        />
    </div>;
}

export default connect(
    (state: ApplicationState) => state.store,
    Store.actionCreators
)(Users as any);
