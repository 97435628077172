import { Action, Reducer } from 'redux';
import { ApplicationState, AppThunkAction } from './';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface StoreState {
    isLoading: boolean;
    isLoadingSave: boolean;
    isLoadingDelete: boolean;
    connections: Connection[];
    connection: Connection | null;
    users: User[];
    user: User | null;
    statuses: Status[];
    status: Status | null;
    identities: Identity[];
    identity: Identity | null;
    softwares: Software[];
    software: Software | null;
    softwareVersions: SoftwareVersion[];
    softwareVersion: SoftwareVersion | null;
    identitySoftwares: IdentitySoftware[];
    identitySoftware: IdentitySoftware | null;
    success: boolean;
    message: string;

    CurrentUser: User | null;
    Hash: string | null;
    HashExipre: Date | null;

    identityCode: string | null;
}
export interface Connection {
    ID: number;
    ServerName: string;
    InitialCatalog: string;
    Login: string;
    Password: string;
    Description: string;
    FIDIdentity: number | null;
}
export interface User {
    ID: number;
    Username: string;
    Password: string;
    IsAdmin: boolean;
    Name: string;
    Permissions: { [key: string]: boolean };
}
export interface Status {
    ID: number;
    Code: string;
    Denomination: string;
}
export interface Software {
    ID: number;
    Code: string;
    Denomination: string;
}
export interface SoftwareVersion {
    ID: number;
    FIDSoftware: number;
    Version: string;
    ReleaseNotes: string;
    ReleaseDate: Date;
    DismissDate: Date | null;
    FIDStatus: number;
}
export interface Identity {
    ID: number;
    Code: string;
    Denomination: string;
}
export interface IdentitySoftware {
    ID: number;
    FIDIdentity: number;
    FIDSoftwareVersion: number;
    InstallDate: Date;
}

interface LoginResponse {
    Item1: boolean;
    Item2: User | null;
    Item3: string;
    Item4: string | null;
    Item5: Date | null;
}
interface IdentityCodeResponse {
    Item1: boolean;
    Item2: string | null;
    Item3: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestConnectionListAction {
    type: 'REQUEST_CONNECTION_LIST';
}

interface ReceiveConnectionListAction {
    type: 'RECEIVE_CONNECTION_LIST';
    connections: Connection[];
}
interface RequestConnectionAction {
    type: 'REQUEST_CONNECTION';
}

interface ReceiveConnectionAction {
    type: 'RECEIVE_CONNECTION';
    connection: Connection;
}

interface RequestCreateConnectionAction {
    type: 'REQUEST_CREATE_CONNECTION';
}

interface ReceiveCreateConnectionAction {
    type: 'RECEIVE_CREATE_CONNECTION';
    success: boolean;
    message: string;
    connection: Connection;
}

interface RequestUpdateConnectionAction {
    type: 'REQUEST_UPDATE_CONNECTION';
}

interface ReceiveUpdateConnectionAction {
    type: 'RECEIVE_UPDATE_CONNECTION';
    success: boolean;
    message: string;
    connection: Connection;
}

interface RequestDeleteConnectionAction {
    type: 'REQUEST_DELETE_CONNECTION';
}

interface ReceiveDeleteConnectionAction {
    type: 'RECEIVE_DELETE_CONNECTION';
    success: boolean;
    message: string;
}

interface RequestUserListAction {
    type: 'REQUEST_USER_LIST';
}

interface ReceiveUserListAction {
    type: 'RECEIVE_USER_LIST';
    users: User[];
}
interface RequestUserAction {
    type: 'REQUEST_USER';
}

interface ReceiveUserAction {
    type: 'RECEIVE_USER';
    user: User;
}

interface RequestCreateUserAction {
    type: 'REQUEST_CREATE_USER';
}

interface ReceiveCreateUserAction {
    type: 'RECEIVE_CREATE_USER';
    success: boolean;
    message: string;
    user: User;
}

interface RequestUpdateUserAction {
    type: 'REQUEST_UPDATE_USER';
}

interface ReceiveUpdateUserAction {
    type: 'RECEIVE_UPDATE_USER';
    success: boolean;
    message: string;
    user: User;
}

interface RequestDeleteUserAction {
    type: 'REQUEST_DELETE_USER';
}

interface ReceiveDeleteUserAction {
    type: 'RECEIVE_DELETE_USER';
    success: boolean;
    message: string;
}

interface RequestLoginAction {
    type: 'REQUEST_LOGIN';
}
interface ReceiveLoginAction {
    type: 'RECEIVE_LOGIN';
    User: User | null;
    success: boolean;
    message: string;
    Hash: string | null;
    HashExipre: Date | null;
}
interface ReceiveLogoutAction {
    type: 'RECEIVE_LOGOUT';
}
interface RequestCheckLoginAction {
    type: 'REQUEST_CHECK_LOGIN',
}
interface ReceiveCheckLoginAction {
    type: 'RECEIVE_CHECK_LOGIN',
}




interface RequestStatusListAction {
    type: 'REQUEST_STATUS_LIST';
}

interface ReceiveStatusListAction {
    type: 'RECEIVE_STATUS_LIST';
    statuses: Status[];
}
interface RequestStatusAction {
    type: 'REQUEST_STATUS';
}

interface ReceiveStatusAction {
    type: 'RECEIVE_STATUS';
    status: Status;
}

interface RequestCreateStatusAction {
    type: 'REQUEST_CREATE_STATUS';
}

interface ReceiveCreateStatusAction {
    type: 'RECEIVE_CREATE_STATUS';
    success: boolean;
    message: string;
    status: Status;
}

interface RequestUpdateStatusAction {
    type: 'REQUEST_UPDATE_STATUS';
}

interface ReceiveUpdateStatusAction {
    type: 'RECEIVE_UPDATE_STATUS';
    success: boolean;
    message: string;
    status: Status;
}

interface RequestDeleteStatusAction {
    type: 'REQUEST_DELETE_STATUS';
}

interface ReceiveDeleteStatusAction {
    type: 'RECEIVE_DELETE_STATUS';
    success: boolean;
    message: string;
}



interface RequestIdentityListAction {
    type: 'REQUEST_IDENTITY_LIST';
}

interface ReceiveIdentityListAction {
    type: 'RECEIVE_IDENTITY_LIST';
    identities: Identity[];
}
interface RequestIdentityAction {
    type: 'REQUEST_IDENTITY';
}

interface ReceiveIdentityAction {
    type: 'RECEIVE_IDENTITY';
    identity: Identity;
}

interface RequestCreateIdentityAction {
    type: 'REQUEST_CREATE_IDENTITY';
}

interface ReceiveCreateIdentityAction {
    type: 'RECEIVE_CREATE_IDENTITY';
    success: boolean;
    message: string;
    identity: Identity;
}

interface RequestUpdateIdentityAction {
    type: 'REQUEST_UPDATE_IDENTITY';
}

interface ReceiveUpdateIdentityAction {
    type: 'RECEIVE_UPDATE_IDENTITY';
    success: boolean;
    message: string;
    identity: Identity;
}

interface RequestDeleteIdentityAction {
    type: 'REQUEST_DELETE_IDENTITY';
}

interface ReceiveDeleteIdentityAction {
    type: 'RECEIVE_DELETE_IDENTITY';
    success: boolean;
    message: string;
}


interface RequestSoftwareListAction {
    type: 'REQUEST_SOFTWARE_LIST';
}

interface ReceiveSoftwareListAction {
    type: 'RECEIVE_SOFTWARE_LIST';
    softwares: Software[];
}
interface RequestSoftwareAction {
    type: 'REQUEST_SOFTWARE';
}

interface ReceiveSoftwareAction {
    type: 'RECEIVE_SOFTWARE';
    software: Software;
}

interface RequestCreateSoftwareAction {
    type: 'REQUEST_CREATE_SOFTWARE';
}

interface ReceiveCreateSoftwareAction {
    type: 'RECEIVE_CREATE_SOFTWARE';
    success: boolean;
    message: string;
    software: Software;
}

interface RequestUpdateSoftwareAction {
    type: 'REQUEST_UPDATE_SOFTWARE';
}

interface ReceiveUpdateSoftwareAction {
    type: 'RECEIVE_UPDATE_SOFTWARE';
    success: boolean;
    message: string;
    software: Software;
}

interface RequestDeleteSoftwareAction {
    type: 'REQUEST_DELETE_SOFTWARE';
}

interface ReceiveDeleteSoftwareAction {
    type: 'RECEIVE_DELETE_SOFTWARE';
    success: boolean;
    message: string;
}



interface RequestSoftwareVersionListAction {
    type: 'REQUEST_SOFTWAREVERSION_LIST';
}

interface ReceiveSoftwareVersionListAction {
    type: 'RECEIVE_SOFTWAREVERSION_LIST';
    softwareVersions: SoftwareVersion[];
}
interface RequestSoftwareVersionAction {
    type: 'REQUEST_SOFTWAREVERSION';
}

interface ReceiveSoftwareVersionAction {
    type: 'RECEIVE_SOFTWAREVERSION';
    softwareVersion: SoftwareVersion;
}

interface RequestCreateSoftwareVersionAction {
    type: 'REQUEST_CREATE_SOFTWAREVERSION';
}

interface ReceiveCreateSoftwareVersionAction {
    type: 'RECEIVE_CREATE_SOFTWAREVERSION';
    success: boolean;
    message: string;
    softwareVersion: SoftwareVersion;
}

interface RequestUpdateSoftwareVersionAction {
    type: 'REQUEST_UPDATE_SOFTWAREVERSION';
}

interface ReceiveUpdateSoftwareVersionAction {
    type: 'RECEIVE_UPDATE_SOFTWAREVERSION';
    success: boolean;
    message: string;
    softwareVersion: SoftwareVersion;
}

interface RequestDeleteSoftwareVersionAction {
    type: 'REQUEST_DELETE_SOFTWAREVERSION';
}

interface ReceiveDeleteSoftwareVersionAction {
    type: 'RECEIVE_DELETE_SOFTWAREVERSION';
    success: boolean;
    message: string;
}




interface RequestIdentitySoftwareListAction {
    type: 'REQUEST_IDENTITYSOFTWARE_LIST';
}

interface ReceiveIdentitySoftwareListAction {
    type: 'RECEIVE_IDENTITYSOFTWARE_LIST';
    identitySoftwares: IdentitySoftware[];
}
interface RequestIdentitySoftwareAction {
    type: 'REQUEST_IDENTITYSOFTWARE';
}

interface ReceiveIdentitySoftwareAction {
    type: 'RECEIVE_IDENTITYSOFTWARE';
    identitySoftware: IdentitySoftware;
}

interface RequestCreateIdentitySoftwareAction {
    type: 'REQUEST_CREATE_IDENTITYSOFTWARE';
}

interface ReceiveCreateIdentitySoftwareAction {
    type: 'RECEIVE_CREATE_IDENTITYSOFTWARE';
    success: boolean;
    message: string;
    identitySoftware: IdentitySoftware;
}

interface RequestUpdateIdentitySoftwareAction {
    type: 'REQUEST_UPDATE_IDENTITYSOFTWARE';
}

interface ReceiveUpdateIdentitySoftwareAction {
    type: 'RECEIVE_UPDATE_IDENTITYSOFTWARE';
    success: boolean;
    message: string;
    identitySoftware: IdentitySoftware;
}

interface RequestDeleteIdentitySoftwareAction {
    type: 'REQUEST_DELETE_IDENTITYSOFTWARE';
}

interface ReceiveDeleteIdentitySoftwareAction {
    type: 'RECEIVE_DELETE_IDENTITYSOFTWARE';
    success: boolean;
    message: string;
}


interface RequesIdentityCode {
    type: 'REQUEST_IDENTITY_CODE';
}
interface ClearIdentityCode {
    type: 'CLEAR_IDENTITY_CODE';
}

interface ReceiveIdentityCode {
    type: 'RECEIVE_IDENTITY_CODE';
    success: boolean;
    message: string;
    code: string;
}




// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestConnectionListAction | ReceiveConnectionListAction
    | RequestConnectionAction | ReceiveConnectionAction
    | RequestCreateConnectionAction | ReceiveCreateConnectionAction
    | RequestUpdateConnectionAction | ReceiveUpdateConnectionAction
    | RequestDeleteConnectionAction | ReceiveDeleteConnectionAction
    | RequestUserListAction | ReceiveUserListAction
    | RequestUserAction | ReceiveUserAction
    | RequestCreateUserAction | ReceiveCreateUserAction
    | RequestUpdateUserAction | ReceiveUpdateUserAction
    | RequestDeleteUserAction | ReceiveDeleteUserAction
    | RequestLoginAction | ReceiveLoginAction
    | ReceiveLogoutAction
    | RequestCheckLoginAction | ReceiveCheckLoginAction
    | RequestStatusListAction | ReceiveStatusListAction
    | RequestStatusAction | ReceiveStatusAction
    | RequestCreateStatusAction | ReceiveCreateStatusAction
    | RequestUpdateStatusAction | ReceiveUpdateStatusAction
    | RequestDeleteStatusAction | ReceiveDeleteStatusAction

    | RequestIdentityListAction | ReceiveIdentityListAction
    | RequestIdentityAction | ReceiveIdentityAction
    | RequestCreateIdentityAction | ReceiveCreateIdentityAction
    | RequestUpdateIdentityAction | ReceiveUpdateIdentityAction
    | RequestDeleteIdentityAction | ReceiveDeleteIdentityAction

    | RequestSoftwareListAction | ReceiveSoftwareListAction
    | RequestSoftwareAction | ReceiveSoftwareAction
    | RequestCreateSoftwareAction | ReceiveCreateSoftwareAction
    | RequestUpdateSoftwareAction | ReceiveUpdateSoftwareAction
    | RequestDeleteSoftwareAction | ReceiveDeleteSoftwareAction

    | RequestSoftwareVersionListAction | ReceiveSoftwareVersionListAction
    | RequestSoftwareVersionAction | ReceiveSoftwareVersionAction
    | RequestCreateSoftwareVersionAction | ReceiveCreateSoftwareVersionAction
    | RequestUpdateSoftwareVersionAction | ReceiveUpdateSoftwareVersionAction
    | RequestDeleteSoftwareVersionAction | ReceiveDeleteSoftwareVersionAction

    | RequestIdentitySoftwareListAction | ReceiveIdentitySoftwareListAction
    | RequestIdentitySoftwareAction | ReceiveIdentitySoftwareAction
    | RequestCreateIdentitySoftwareAction | ReceiveCreateIdentitySoftwareAction
    | RequestUpdateIdentitySoftwareAction | ReceiveUpdateIdentitySoftwareAction
    | RequestDeleteIdentitySoftwareAction | ReceiveDeleteIdentitySoftwareAction

    | RequesIdentityCode | ReceiveIdentityCode | ClearIdentityCode ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


const refreshToken = (dispatch: any, getState: any) => {
    const appState = getState();
    if (appState && appState.store && appState.store.Hash) {
        fetch('/api/Auth/RefershToken', {
            headers: new Headers({
                'Authorization': `Hash ${appState.store.Hash}`
            })
        })
            .then(res => res.json() as Promise<LoginResponse>)
            .then(data => {
                if (data.Item1) {
                    dispatch({ type: 'RECEIVE_LOGIN', success: data.Item1, User: data.Item2, message: data.Item3, Hash: data.Item4, HashExipre: data.Item5 });
                }
                else {
                    dispatch({ type: 'RECEIVE_LOGOUT' });
                }
            });
    }
}
const checkHash = (dispatch:any, getState: any):boolean => {
    const appState = getState();
    var now = new Date();
    var now2 = now;
    now2.setMinutes(now2.getMinutes() + 10);
    if (appState && appState.store && appState.store.HashExipre && appState.store.Hash ) {
        var hashExpire = new Date(Date.parse(appState.store.HashExipre.toString()))
        if (hashExpire > new Date() && hashExpire < now2) {
            refreshToken(dispatch, getState);
            return true;
        }
        else if (hashExpire < new Date()) {
            dispatch({ type: 'RECEIVE_LOGOUT' });
        }
        else {
            return true;
        }
    }
    dispatch({ type: 'RECEIVE_LOGOUT' });
    return false;
}
const getHash = (getState: any): string => {
    const appState = getState();
    const hash = (appState && appState.store && appState.store.Hash) || "";
    return hash;
}
const setCookie = (name: string, value: string, validity: number) => {
    var d = new Date();
    d.setTime(d.getTime() + (validity * 1000 * 60))
    var expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
}
const getCookie = (cname: string): string => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const actionCreators = {
    requestConnectionList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Connection/GetConnections`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Connection[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CONNECTION_LIST', connections: data });
            });

        dispatch({ type: 'REQUEST_CONNECTION_LIST' });
    },
    requestConnection: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Connection/GetConnection/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Connection>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CONNECTION', connection: data });
            });

        dispatch({ type: 'REQUEST_CONNECTION' });
    },
    createConnection: (connection: Connection): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Connection/CreateConnection`, {
            method: 'POST',
            body: JSON.stringify(connection),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Connection>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_CONNECTION', success: true, connection: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_CONNECTION' });
    },
    updateConnection: (connection: Connection): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Connection/UpdateConnection`, {
            method: 'PUT',
            body: JSON.stringify(connection),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Connection>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_CONNECTION', success: true, connection: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_CONNECTION' });
    },
    deleteConnection: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Connection/DeleteConnection/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_CONNECTION', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_CONNECTION' });
    },
    requestUserList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch,getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/User/GetUsers`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<User[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_USER_LIST', users: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_USER_LIST', users: [] });
            });

        dispatch({ type: 'REQUEST_USER_LIST' });
    },
    requestUser: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/User/GetUser/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<User>)
            .then(data => {
                dispatch({ type: 'RECEIVE_USER', user: data });
            });

        dispatch({ type: 'REQUEST_USER' });
    },
    createUser: (user: User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/User/CreateUser`, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<User>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_USER', success: true, user: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_USER' });
    },
    updateUser: (user: User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/User/UpdateUser`, {
            method: 'PUT',
            body: JSON.stringify(user),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<User>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_USER', success: true, user: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_USER' });
    },
    deleteUser: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/User/DeleteUser/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_USER', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_USER' });
    },


    requestStatusList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Status/GetStatuses`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Status[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_STATUS_LIST', statuses: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_STATUS_LIST', statuses: [] });
            });

        dispatch({ type: 'REQUEST_STATUS_LIST' });
    },
    requestStatus: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Status/GetStatus/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Status>)
            .then(data => {
                dispatch({ type: 'RECEIVE_STATUS', status: data });
            });

        dispatch({ type: 'REQUEST_STATUS' });
    },
    createStatus: (status: Status): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Status/CreateStatus`, {
            method: 'POST',
            body: JSON.stringify(status),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Status>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_STATUS', success: true, status: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_STATUS' });
    },
    updateStatus: (status: Status): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Status/UpdateStatus`, {
            method: 'PUT',
            body: JSON.stringify(status),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Status>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_STATUS', success: true, status: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_STATUS' });
    },
    deleteStatus: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Status/DeleteStatus/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_STATUS', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_STATUS' });
    },


    requestIdentityList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Identity/GetIdentities`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Identity[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_IDENTITY_LIST', identities: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_IDENTITY_LIST', identities: [] });
            });

        dispatch({ type: 'REQUEST_IDENTITY_LIST' });
    },
    requestIdentity: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Identity/GetIdentity/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Identity>)
            .then(data => {
                dispatch({ type: 'RECEIVE_IDENTITY', identity: data });
            });

        dispatch({ type: 'REQUEST_IDENTITY' });
    },
    createIdentity: (identity: Identity): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Identity/CreateIdentity`, {
            method: 'POST',
            body: JSON.stringify(identity),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Identity>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_IDENTITY', success: true, identity: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_IDENTITY' });
    },
    updateIdentity: (identity: Identity): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Identity/UpdateIdentity`, {
            method: 'PUT',
            body: JSON.stringify(identity),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Identity>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_IDENTITY', success: true, identity: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_IDENTITY' });
    },
    deleteIdentity: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Identity/DeleteIdentity/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_IDENTITY', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_IDENTITY' });
    },


    requestSoftwareList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Software/GetSoftwares`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Software[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_SOFTWARE_LIST', softwares: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_SOFTWARE_LIST', softwares: [] });
            });

        dispatch({ type: 'REQUEST_SOFTWARE_LIST' });
    },
    requestSoftware: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Software/GetSoftware/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Software>)
            .then(data => {
                dispatch({ type: 'RECEIVE_SOFTWARE', software: data });
            });

        dispatch({ type: 'REQUEST_SOFTWARE' });
    },
    createSoftware: (software: Software): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Software/CreateSoftware`, {
            method: 'POST',
            body: JSON.stringify(software),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Software>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_SOFTWARE', success: true, software: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_SOFTWARE' });
    },
    updateSoftware: (software: Software): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Software/UpdateSoftware`, {
            method: 'PUT',
            body: JSON.stringify(software),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<Software>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_SOFTWARE', success: true, software: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_SOFTWARE' });
    },
    deleteSoftware: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/Software/DeleteSoftware/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_SOFTWARE', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_SOFTWARE' });
    },


    requestSoftwareVersionList: (FIDSoftware: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/SoftwareVersion/GetSoftwareVersions/${FIDSoftware}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<SoftwareVersion[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_SOFTWAREVERSION_LIST', softwareVersions: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_SOFTWAREVERSION_LIST', softwareVersions: [] });
            });

        dispatch({ type: 'REQUEST_SOFTWAREVERSION_LIST' });
    },
    requestSoftwareVersion: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/SoftwareVersion/GetSoftwareVersion/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<SoftwareVersion>)
            .then(data => {
                dispatch({ type: 'RECEIVE_SOFTWAREVERSION', softwareVersion: data });
            });

        dispatch({ type: 'REQUEST_SOFTWAREVERSION' });
    },
    createSoftwareVersion: (softwareVersion: SoftwareVersion): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/SoftwareVersion/CreateSoftwareVersion`, {
            method: 'POST',
            body: JSON.stringify(softwareVersion),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<SoftwareVersion>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_SOFTWAREVERSION', success: true, softwareVersion: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_SOFTWAREVERSION' });
    },
    updateSoftwareVersion: (softwareVersion: SoftwareVersion): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/SoftwareVersion/UpdateSoftwareVersion`, {
            method: 'PUT',
            body: JSON.stringify(softwareVersion),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<SoftwareVersion>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_SOFTWAREVERSION', success: true, softwareVersion: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_SOFTWAREVERSION' });
    },
    deleteSoftwareVersion: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/SoftwareVersion/DeleteSoftwareVersion/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_SOFTWAREVERSION', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_SOFTWAREVERSION' });
    },


    requestIdentitySoftwareList: (FIDIdentity: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/IdentitySoftware/GetIdentitySoftwares/${FIDIdentity}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<IdentitySoftware[]>)
            .then(data => {
                dispatch({ type: 'RECEIVE_IDENTITYSOFTWARE_LIST', identitySoftwares: data });
            })
            .catch(e => {
                dispatch({ type: 'RECEIVE_IDENTITYSOFTWARE_LIST', identitySoftwares: [] });
            });

        dispatch({ type: 'REQUEST_IDENTITYSOFTWARE_LIST' });
    },
    requestIdentitySoftware: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/IdentitySoftware/GetIdentitySoftware/${id}`, {
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<IdentitySoftware>)
            .then(data => {
                dispatch({ type: 'RECEIVE_IDENTITYSOFTWARE', identitySoftware: data });
            });

        dispatch({ type: 'REQUEST_IDENTITYSOFTWARE' });
    },
    createIdentitySoftware: (identitySoftware: IdentitySoftware): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/IdentitySoftware/CreateIdentitySoftware`, {
            method: 'POST',
            body: JSON.stringify(identitySoftware),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<IdentitySoftware>)
            .then(data => {
                dispatch({ type: 'RECEIVE_CREATE_IDENTITYSOFTWARE', success: true, identitySoftware: data, message: '' });
            });

        dispatch({ type: 'REQUEST_CREATE_IDENTITYSOFTWARE' });
    },
    updateIdentitySoftware: (identitySoftware: IdentitySoftware): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/IdentitySoftware/UpdateIdentitySoftware`, {
            method: 'PUT',
            body: JSON.stringify(identitySoftware),
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<IdentitySoftware>)
            .then(data => {
                dispatch({ type: 'RECEIVE_UPDATE_IDENTITYSOFTWARE', success: true, identitySoftware: data, message: '' });
            });

        dispatch({ type: 'REQUEST_UPDATE_IDENTITYSOFTWARE' });
    },
    deleteIdentitySoftware: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!checkHash(dispatch, getState)) {
            return;
        }
        var hash: string = getHash(getState);
        fetch(`/api/IdentitySoftware/DeleteIdentitySoftware/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': `Hash ${hash}`
            })
        })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'RECEIVE_DELETE_IDENTITYSOFTWARE', success: data, message: 'Dati non eliminati' });
            });

        dispatch({ type: 'REQUEST_DELETE_IDENTITYSOFTWARE' });
    },

    login: (username: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        //const appState = getState();
        fetch(`/api/Auth/Login?username=${username}&password=${password}`)
            .then(response => response.json() as Promise<LoginResponse>)
            .then(data => {
                dispatch({ type: 'RECEIVE_LOGIN', success: data.Item1, User: data.Item2, message: data.Item3, Hash: data.Item4, HashExipre: data.Item5 });
            });

        dispatch({ type: 'REQUEST_LOGIN' });
    },
    checkLogin: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var hash = getCookie("hash");
        if (hash) {
            fetch('/api/Auth/RefershToken', {
                headers: new Headers({
                    'Authorization': `Hash ${hash}`
                })
            })
                .then(res => res.json() as Promise<LoginResponse>)
                .then(data => {
                    if (data.Item1) {
                        dispatch({ type: 'RECEIVE_LOGIN', success: data.Item1, User: data.Item2, message: data.Item3, Hash: data.Item4, HashExipre: data.Item5 });
                    }
                    else {
                        dispatch({ type: 'RECEIVE_LOGOUT' });
                    }
                });
            dispatch({ type: 'REQUEST_CHECK_LOGIN' });
        }
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RECEIVE_LOGOUT' });
    },

    getIdentityCode: (denomination: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`/api/Identity/GetIdentityCode?Denomination=${denomination}`)
            .then(res => res.json() as Promise<IdentityCodeResponse>)
            .then(data => {

                dispatch({ type: 'RECEIVE_IDENTITY_CODE', success: data.Item1, code: data.Item2 || "", message: data.Item3 });
            })
        dispatch({ type: 'REQUEST_IDENTITY_CODE' });
    },
    clearIdentityCode: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_IDENTITY_CODE' });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: StoreState = {
    connections: [], connection: null, isLoading: false, isLoadingDelete: false, isLoadingSave: false, success: false, message: '', users: [], user: null,
    CurrentUser: null,
    Hash: null, HashExipre: null,
    statuses: [], status: null,
    identities:[], identity: null,
    softwares: [], software: null,
    softwareVersions: [], softwareVersion: null,
    identitySoftwares: [], identitySoftware: null,
    identityCode: null
};

export const reducer: Reducer<StoreState> = (state: StoreState | undefined, incomingAction: Action): StoreState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CONNECTION_LIST':
            return { ...state, isLoading: true, connection: null };
        case 'RECEIVE_CONNECTION_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_CONNECTION':
            return { ...state, isLoading: true };
        case 'RECEIVE_CONNECTION':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_CONNECTION':
            return { ...state, isLoading: true, isLoadingSave: true };
        case 'RECEIVE_CREATE_CONNECTION':
            return { ...state, isLoading: false, isLoadingSave: false, ...action };
        case 'REQUEST_UPDATE_CONNECTION':
            return { ...state, isLoading: true, isLoadingSave: true };
        case 'RECEIVE_UPDATE_CONNECTION':
            return { ...state, isLoading: false, isLoadingSave: false, ...action };
        case 'REQUEST_DELETE_CONNECTION':
            return { ...state, isLoading: true, isLoadingDelete: true, };
        case 'RECEIVE_DELETE_CONNECTION':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };
        case 'REQUEST_USER_LIST':
            return { ...state, isLoading: true, user: null };
        case 'RECEIVE_USER_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_USER':
            return { ...state, isLoading: true };
        case 'RECEIVE_USER':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_USER':
            return { ...state, isLoading: true, isLoadingSave: true };
        case 'RECEIVE_CREATE_USER':
            return { ...state, isLoading: false, isLoadingSave: false, ...action };
        case 'REQUEST_UPDATE_USER':
            return { ...state, isLoading: true, isLoadingSave: true };
        case 'RECEIVE_UPDATE_USER':
            return { ...state, isLoading: false, isLoadingSave: false, ...action };
        case 'REQUEST_DELETE_USER':
            return { ...state, isLoading: true, isLoadingDelete: true, };
        case 'RECEIVE_DELETE_USER':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };

        case 'REQUEST_STATUS_LIST':
            return { ...state, isLoading: true, status: null };
        case 'RECEIVE_STATUS_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_STATUS':
            return { ...state, isLoading: true, status: null };
        case 'RECEIVE_STATUS':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_STATUS':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_CREATE_STATUS':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_UPDATE_STATUS':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_UPDATE_STATUS':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_DELETE_STATUS':
            return { ...state, isLoading: true, isLoadingDelete: true };
        case 'RECEIVE_DELETE_STATUS':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };



        case 'REQUEST_IDENTITY_LIST':
            return { ...state, isLoading: true, identity: null };
        case 'RECEIVE_IDENTITY_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_IDENTITY':
            return { ...state, isLoading: true, identity: null };
        case 'RECEIVE_IDENTITY':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_IDENTITY':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_CREATE_IDENTITY':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_UPDATE_IDENTITY':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_UPDATE_IDENTITY':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_DELETE_IDENTITY':
            return { ...state, isLoading: true, isLoadingDelete: true };
        case 'RECEIVE_DELETE_IDENTITY':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };

        case 'REQUEST_SOFTWARE_LIST':
            return { ...state, isLoading: true, software: null };
        case 'RECEIVE_SOFTWARE_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_SOFTWARE':
            return { ...state, isLoading: true, software: null };
        case 'RECEIVE_SOFTWARE':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_SOFTWARE':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_CREATE_SOFTWARE':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_UPDATE_SOFTWARE':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_UPDATE_SOFTWARE':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_DELETE_SOFTWARE':
            return { ...state, isLoading: true, isLoadingDelete: true };
        case 'RECEIVE_DELETE_SOFTWARE':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };



        case 'REQUEST_SOFTWAREVERSION_LIST':
            return { ...state, isLoading: true, softwareVersion: null };
        case 'RECEIVE_SOFTWAREVERSION_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_SOFTWAREVERSION':
            return { ...state, isLoading: true, softwareVersion: null };
        case 'RECEIVE_SOFTWAREVERSION':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_SOFTWAREVERSION':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_CREATE_SOFTWAREVERSION':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_UPDATE_SOFTWAREVERSION':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_UPDATE_SOFTWAREVERSION':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_DELETE_SOFTWAREVERSION':
            return { ...state, isLoading: true, isLoadingDelete: true };
        case 'RECEIVE_DELETE_SOFTWAREVERSION':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };


        case 'REQUEST_IDENTITYSOFTWARE_LIST':
            return { ...state, isLoading: true, identitySoftware: null };
        case 'RECEIVE_IDENTITYSOFTWARE_LIST':
            if (state.isLoading) {
                return { ...state, isLoading: false, ...action };
            }
            break;
        case 'REQUEST_IDENTITYSOFTWARE':
            return { ...state, isLoading: true, identitySoftware: null };
        case 'RECEIVE_IDENTITYSOFTWARE':
            return { ...state, isLoading: false, ...action };
        case 'REQUEST_CREATE_IDENTITYSOFTWARE':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_CREATE_IDENTITYSOFTWARE':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_UPDATE_IDENTITYSOFTWARE':
            return { ...state, isLoadingSave: true, isLoading: true };
        case 'RECEIVE_UPDATE_IDENTITYSOFTWARE':
            return { ...state, isLoadingSave: false, isLoading: false, ...action };
        case 'REQUEST_DELETE_IDENTITYSOFTWARE':
            return { ...state, isLoading: true, isLoadingDelete: true };
        case 'RECEIVE_DELETE_IDENTITYSOFTWARE':
            return { ...state, isLoading: false, isLoadingDelete: false, ...action };



        case 'REQUEST_LOGIN':
            return { ...state, isLoading: true, CurrentUser: null, Hash: null, HashExipre: null };
        case 'RECEIVE_LOGIN':
            if (state.isLoading) {
                if (action.Hash) {
                    setCookie("hash", action.Hash, 300);
                }
                var result: StoreState = { ...state, isLoading: false, CurrentUser: action.User, ...action }
                return result;
            }
            break;
        case 'RECEIVE_LOGOUT':
            return { ...unloadedState };
        case 'REQUEST_CHECK_LOGIN':
            return { ...state, isLoading: true, CurrentUser: null, Hash: null, HashExipre: null };
        case 'RECEIVE_IDENTITY_CODE':
            return { ...state, identityCode: action.code };
        case 'CLEAR_IDENTITY_CODE':
            return { ...state, identityCode: null };
    }
    return state;
};
