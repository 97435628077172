import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import * as Store from '../store/Store';

import { TextField, Grid, Button, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';


const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: '#fff',
    },
});
interface Match {
    id: string | undefined;
}

type ConnectionProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<Match>;


const Connection = (props: ConnectionProps) => {
    const [connection, setConnection] = useState<Store.Connection>({
        ID: 0,
        ServerName: '',
        InitialCatalog: '',
        Login: '',
        Password: '',
        Description: '',
        FIDIdentity: 0
    });
    useEffect(() => {
        if (props.match.params.id) {
            var id: number = parseInt(props.match.params.id);
            if (id) {
                props.requestConnection(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.connection) {
            setConnection({ ...props.connection });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingSave, props.isLoading]);



    const onSave = () => {
        if (connection !== null) {
            if (connection.ID > 0) {
                props.updateConnection(connection);
            }
            else {
                props.createConnection(connection);
            }
        }
    }
    const onBack = () => {
        props.history.push(`/Connections`);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setConnection({ ...connection, [key]: newValue });
    }
    return <div>
        {!props.isLoading && <Grid container spacing={2} className={props.classes.root}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Description" value={connection.Description} id="Description" onChange={onChange("Description")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Server name" value={connection.ServerName} id="ServerName" onChange={onChange("ServerName")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Initial catalog" value={connection.InitialCatalog} id="InitialCatalog" onChange={onChange("InitialCatalog")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Login" value={connection.Login} id="Login" onChange={onChange("Login")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth type="password" label="Password" value={connection.Password} id="Password" onChange={onChange("Password")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={2}><Button fullWidth color="primary" variant="contained" onClick={onSave}>SALVA</Button></Grid>
            <Grid item xs={2}><Button fullWidth variant="contained" onClick={onBack}>INDIETRO</Button></Grid>
            <Grid item xs={4}></Grid>

        </Grid>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;

}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(Connection) as any);