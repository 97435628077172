import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import * as Store from '../store/Store';

import { TextField, Checkbox, FormControlLabel, Button, Grid, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';


const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: '#fff',
    },
});

interface Match {
    id: string | undefined;
}

type UserProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<Match>;

interface IUserState {
    ID: number | null;
    Username: string;
    Password: string;
    Name: string;
    IsAdmin: boolean;
} 
const User = (props: UserProps) => {
    const [user, setUser] = useState<Store.User>({
        ID: 0,
        Username: '',
        Password: '',
        Name: '',
        IsAdmin: false,
        Permissions: {}
    });
    const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});
    useEffect(() => {
        if (props.match.params.id) {
            var id: number = parseInt(props.match.params.id);
            if (id) {
                props.requestUser(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.user !== null) {
            setUser(props.user);
            setPermissions(props.user.Permissions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user]);


    const onSave = () => {
        user.Permissions = permissions;
        if (user.ID > 0) {
            props.updateUser(user);
        }
        else {
            props.createUser(user);
        }
    }
    const onBack = () => {
        props.history.push(`/Users`);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;

        setUser({ ...user, [key]: newValue });
    }
    const onCheckChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkValue = event.currentTarget.checked;

        setUser({ ...user, [key]: checkValue });
    }
    const onPermissionChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkValue = event.currentTarget.checked;

        //setUser({ ...user, Permissions: { ...user.Permissions, [key]: checkValue } });
        setPermissions({ ...permissions, [key]: checkValue });
    }
    return <div>
        {!props.isLoading && <Grid className={props.classes.root} container spacing={2}>
            <Grid item container xs={4} />
            <Grid item container xs={4} spacing={2}>
                <Grid item xs={12}><TextField fullWidth label="Nome" value={user.Name} id="Name" onChange={onChange("Name")} /></Grid>
                <Grid item xs={12}><TextField fullWidth label="Username" value={user.Username} id="Username" onChange={onChange("Username")} /></Grid>
                <Grid item xs={12}><TextField fullWidth type="password" label="Password" value={user.Password} id="Password" onChange={onChange("Password")} /></Grid>
                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={user.IsAdmin} onChange={onCheckChange("IsAdmin")} name="IsAdmin" />}
                    label="Admin"
                /></Grid>

                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.ViewUsers} onChange={onPermissionChange("ViewUsers")} name="ViewUsers" />}
                    label="Visualizza elenco utenti"
                /></Grid>
                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.CreateUsers} onChange={onPermissionChange("CreateUsers")} name="CreateUsers" />}
                    label="Crea/Modifica utenti"
                /></Grid>
                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.DeleteUsers} onChange={onPermissionChange("DeleteUsers")} name="DeleteUsers" />}
                    label="Elimina utenti"
                /></Grid>

                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.ViewConnections} onChange={onPermissionChange("ViewConnections")} name="ViewConnections" />}
                    label="Visualizza elenco connessioni"
                /></Grid>
                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.CreateConnections} onChange={onPermissionChange("CreateConnections")} name="CreateConnections" />}
                    label="Crea/Modifica connessioni"
                /></Grid>
                <Grid item xs={12}><FormControlLabel
                    control={<Checkbox checked={permissions.DeleteConnections} onChange={onPermissionChange("DeleteConnections")} name="DeleteConnections" />}
                    label="Elimina connessioni"
                /></Grid>

                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="primary" onClick={onSave}>SALVA</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" onClick={onBack}>INDIETRO</Button>
                </Grid>
            </Grid>
            <Grid item container xs={4} />
        </Grid>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;
}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(User) as any);
