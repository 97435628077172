import * as React from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Users from './components/Users';
import User from './components/User';
import Login from './components/Login';
import Connections from './components/Connections';
import Connection from './components/Connection';
import Status from './components/Status';
import Statuses from './components/Statuses';

import Software from './components/Software';
import Softwares from './components/Softwares';
import SoftwareVersion from './components/SoftwareVersion';
import SoftwareVersions from './components/SoftwareVersions';

import Identity from './components/Identity';
import Identities from './components/Identities';

import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from './store/index'

import * as Store from './store/Store';

const styles = ({ palette, spacing }: Theme) => createStyles({
    '@global': {
        a: {
            color: '#0366d6'
        },
        code: {
            color: '#E01A76'
        },
        ".btn-primary": {
            color: '#fff',
            backgroundColor: '#1b6ec2',
            borderColor: '#1861ac',
        }
    }
});



type AppProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<{}>;

const App = (props: AppProps) => {
    useEffect(() => {
        props.checkLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.CurrentUser) {
        return (<Layout>

            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewConnections) && <Route exact path='/' component={Connections} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateUsers) && <Route exact path='/user/create' component={User} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateUsers) && <Route exact path='/user/edit/:id' component={User} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewUsers) && <Route exact path='/users' component={Users} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateConnections) && <Route exact path='/connection/create' component={Connection} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateConnections) && <Route exact path='/connection/edit/:id' component={Connection} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewConnections) && <Route exact path='/connections' component={Connections} />}


            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateStatuses) && <Route exact path='/status/create' component={Status} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateStatuses) && <Route exact path='/status/edit/:id' component={Status} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewStatuses) && <Route exact path='/statuses' component={Statuses} />}


            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateSoftwareVersions) && <Route exact path='/softwareVersion/create/:FIDSoftware/' component={SoftwareVersion} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateSoftwareVersions) && <Route exact path='/softwareVersion/edit/:FIDSoftware/:id' component={SoftwareVersion} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewSoftwareVersions) && <Route exact path='/softwareVersions/:FIDSoftware' component={SoftwareVersions} />}

            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateSoftwares) && <Route exact path='/software/create' component={Software} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateSoftwares) && <Route exact path='/software/edit/:id' component={Software} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewSoftwares) && <Route exact path='/softwares' component={Softwares} />}

            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateIdentities) && <Route exact path='/identity/create' component={Identity} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.CreateIdentities) && <Route exact path='/identity/edit/:id' component={Identity} />}
            {(props.CurrentUser.IsAdmin || props.CurrentUser.Permissions.ViewIdentities) && <Route exact path='/identities' component={Identities} />}

        </Layout>
        );
    }

    return (<Route path='/' component={Login} />);
}



var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)

export default enhance(withStyles(styles)(App) as any);