import * as React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'

import * as Store from '../store/Store';
import { AppBar, createStyles, withStyles, WithStyles, Theme, Toolbar, Typography, Button } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        position: 'sticky',
        background: '#fff',
        top: 0,
    },
    grow: {
        flexGrow: 1,
    },
    button: {
        color: '#fff',
        '&:hover': {
            color: '#fff',
        }
    },
    actions: {

    }
});



type UserProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<{}>;

const NavMenu = (props: UserProps) => {
    //const [isOpen, setIsOpen] = React.useState<boolean>(false);
    //const toggle = () => {
    //    setIsOpen(!isOpen);
    //}
    return (
        <AppBar color="primary" position="sticky">
            <Toolbar>
                <Typography>WebData Aruba</Typography>
                <div className={props.classes.grow}></div>
                <div className={props.classes.actions}>
                    {(props.CurrentUser?.IsAdmin || props.CurrentUser?.Permissions.ViewConnections) && <Button className={props.classes.button} color="inherit" component={Link} to="/connections">Connections</Button>}
                    {(props.CurrentUser?.IsAdmin || props.CurrentUser?.Permissions.ViewUsers) && <Button className={props.classes.button} color="inherit" component={Link} to="/users">Users</Button>}
                    {(props.CurrentUser?.IsAdmin || props.CurrentUser?.Permissions.ViewStatuses) && <Button className={props.classes.button} color="inherit" component={Link} to="/statuses">Statuses</Button>}
                    {(props.CurrentUser?.IsAdmin || props.CurrentUser?.Permissions.ViewSoftwares) && <Button className={props.classes.button} color="inherit" component={Link} to="/softwares">Softwares</Button>}
                    {(props.CurrentUser?.IsAdmin || props.CurrentUser?.Permissions.ViewIdentities) && <Button className={props.classes.button} color="inherit" component={Link} to="/identities">Identities</Button>}
                    <Button className={props.classes.button} color="inherit" onClick={props.logout}>Logout</Button>
                </div>
            </Toolbar>
        </AppBar>
    );
}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(NavMenu) as any);