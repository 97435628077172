import * as React from 'react';

import { createStyles, withStyles,WithStyles, Theme } from '@material-ui/core';

const styles = ({ palette }: Theme) => createStyles({
    '@global': {
        body: {
            background: palette.secondary.main,
        }
    },
    root: {
    },
});

interface IProps extends WithStyles<typeof styles> {
    children?: React.ReactNode;
}
const Container = ({ classes, children }: IProps) => {
    return <div className={classes.root}>
        {children}
    </div>;
}

export default withStyles(styles)(Container);
