import * as React from 'react';
import {  useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import * as Store from '../store/Store';
import { compose } from 'redux';

import { TextField, Button, createStyles, withStyles,WithStyles, Theme, Grid, Typography } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: palette.secondary.main,
        width: '100vw',
        height: '100vh',
    },
    loginForm: {
        border: `1px solid ${palette.primary.main}`,
        background: '#fff',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: 300,
        padding: 12,
        boxShadow: `0px 0px 10px ${palette.primary.main}`
    },
    loginHeader: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});


type UserProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<{}>;

interface ILoginState {
    Username: string;
    Password: string;
}
const Login = (props: UserProps) => {
    const [Username, setUsername] = useState<string>("");
    const [Password, setPassword] = useState<string>("");
    const onLogin = () => {
        props.login(Username, Password);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        switch (key) {
            case "Username":
                setUsername(newValue);
                break;
            case "Password":
                setPassword(newValue);
                break;
        }
    }
    return <div className={props.classes.root}>
        {!props.isLoading && <div className={props.classes.loginForm}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={props.classes.loginHeader}><Typography>LOGIN</Typography></Grid>
                <Grid item xs={12}><TextField fullWidth={true} label="Username" value={Username} id="Username" onChange={onChange("Username")} /></Grid>
                <Grid item xs={12}><TextField fullWidth={true} type="password" label="Password" value={Password} id="Password" onChange={onChange("Password")} /></Grid>
                <Grid item xs={12}><Button fullWidth={true} variant="contained" color="primary" onClick={onLogin}>LOGIN</Button></Grid>
                {!props.success && <Grid item xs={12}><Typography>{props.message}</Typography></Grid>}
            </Grid>
        </div>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;
}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
    //withStyles(styles),
)
export default enhance(withStyles(styles)(Login) as any);
