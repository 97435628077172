import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import * as Store from '../store/Store';

import { TextField, Grid, Button, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';


const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: '#fff',
    },
});
interface Match {
    id: string | undefined;
}

type IdentityProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<Match>;


const Identity = (props: IdentityProps) => {
    const [identity, setIdentity] = useState<Store.Identity>({
        ID: 0,
        Code: '',
        Denomination: ''
    });
    useEffect(() => {
        if (props.match.params.id) {
            var id: number = parseInt(props.match.params.id);
            if (id) {
                props.requestIdentity(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.identity) {
            setIdentity({ ...props.identity });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingSave, props.isLoading]);

    useEffect(() => {
        if (props.identityCode) {
            setIdentity({ ...identity, Code: props.identityCode });
            props.clearIdentityCode();
        }
    },[props.identityCode])

    const onSave = () => {
        if (identity !== null) {
            if (identity.ID > 0) {
                props.updateIdentity(identity);
            }
            else {
                props.createIdentity(identity);
            }
        }
    }
    const onBack = () => {
        props.history.push(`/Identities`);
    }
    const onSoftwaresClick = () => {
        props.history.push(`/IdentitySoftwares/${identity.ID}`);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setIdentity({ ...identity, [key]: newValue });
    }
    const onBlur = (key: string) => (focusEvent: React.FocusEvent<HTMLInputElement>) => {
        if (!identity.Code) {
            props.getIdentityCode(identity.Denomination);
        }
    }
    return <div>
        {!props.isLoading && <Grid container spacing={2} className={props.classes.root}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Code" value={identity.Code} id="Code" onChange={onChange("Code")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Denomination" value={identity.Denomination} id="Denomination" onChange={onChange("Denomination")} onBlur={onBlur("Denomination")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={2}><Button fullWidth color="primary" variant="contained" onClick={onSave}>SALVA</Button></Grid>
            <Grid item xs={2}><Button fullWidth variant="contained" onClick={onBack}>INDIETRO</Button></Grid>
            <Grid item xs={4}></Grid>


            <Grid item xs={4}></Grid>
            <Grid item xs={4}><Button fullWidth color="primary" variant="contained" onClick={onSoftwaresClick}>SOFTWARES</Button></Grid>
            <Grid item xs={4}></Grid>

        </Grid>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;

}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(Identity) as any);