import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as Store from '../store/Store';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

type ConnectionProps =
    Store.StoreState &
    typeof Store.actionCreators &
    RouteComponentProps<{}>;

interface IRowData {
    dataIndex: number;
    index: number;
}
interface IRow {
    data: IRowData[];
}

const Connections = (props: ConnectionProps) => {
    useEffect(() => {
        props.requestConnectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.requestConnectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDelete]);
    const onEditConnection = (id: number) => () => {
        props.history.push(`/connection/edit/${id}`);
    }

    const onNewConnection = () => {
        props.history.push(`/connection/create`);
    }
    const onDeleteConnection = (row: IRow) => () => {
        var { connections } = props;
        for (var i in row.data) {
            var data: IRowData = row.data[i];
            var connection: Store.Connection = connections[data.dataIndex];
            props.deleteConnection(connection.ID);
        }
    }
    const onClick = (ID: number) => () => {
        var { connections } = props;
        var data = connections.filter(item => item.ID === ID)[0];
        if (!data) {
            return;
        }
        var f = document.createElement('form');
        f.setAttribute('target', '_blank');
        f.setAttribute('method', 'POST');
        f.setAttribute('style', 'display:none');
        f.setAttribute('action', 'https://webdata.arubabusiness.it/silentlogon.aspx');

        var ServerName = document.createElement('input');
        ServerName.setAttribute('type', 'hidden');
        ServerName.setAttribute('name', 'ServerName');
        ServerName.setAttribute('value', data.ServerName);
        f.appendChild(ServerName);
        var InitialCatalog = document.createElement('input');
        InitialCatalog.setAttribute('type', 'hidden');
        InitialCatalog.setAttribute('name', 'InitialCatalog');
        InitialCatalog.setAttribute('value', data.InitialCatalog);
        f.appendChild(InitialCatalog);
        var Login = document.createElement('input');
        Login.setAttribute('type', 'hidden');
        Login.setAttribute('name', 'Login');
        Login.setAttribute('value', data.Login);
        f.appendChild(Login);
        var Password = document.createElement('input');
        Password.setAttribute('type', 'hidden');
        Password.setAttribute('name', 'Password');
        Password.setAttribute('value', data.Password);
        f.appendChild(Password);


        document.body.appendChild(f);

        f.submit();
        f.remove();
    }

    const columns: MUIDataTableColumnDef[] = [
        { name: "Description", label: 'Descrizione' },
        { name: "ServerName", label: 'Server name' },
        { name: "InitialCatalog", label: 'Database' },
        { name: "Login", label: 'Username' },
        {
            name: "ID", label: 'Actions', options: {
                customBodyRender: (value: number) => (<Button onClick={onClick(value)}>CONNECT</Button>)
            }
        },
    ];
    const customToolbar = () => {
        return <React.Fragment>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateConnections || false)) && <Tooltip title="Nuovo">
                <IconButton onClick={onNewConnection}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </React.Fragment>;
    }
    const customToolbarSelect = (selectedRows: IRow) => {
        var { connections } = props;
        var data: IRowData = selectedRows.data[0];
        var connection: Store.Connection = connections[data.dataIndex];
        return <div>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateConnections || false)) && selectedRows.data.length === 1 && <Tooltip title="Modifica">
                <IconButton onClick={onEditConnection(connection.ID)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.DeleteConnections || false)) && <Tooltip title="Elimina">
                <IconButton onClick={onDeleteConnection(selectedRows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>}
        </div>;
    }

    if (props.isLoading) {
        return <div>Loading</div>;
    }

    return <div>
        <MUIDataTable
            title="Connessioni"
            data={props.connections}
            columns={columns}
            options={{
                filterType: "checkbox",
                download: false,
                print: false,
                viewColumns: false,
                filter: false,
                selectableRowsHeader: props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateConnections || false) || (props.CurrentUser?.Permissions.DeleteConnections || false),
                isRowSelectable: () => props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateConnections || false) || (props.CurrentUser?.Permissions.DeleteConnections || false),
                customToolbar: customToolbar,
                customToolbarSelect: customToolbarSelect
            }}
        />
    </div>;

}

export default connect(
    (state: ApplicationState) => state.store,
    Store.actionCreators
)(Connections as any);
