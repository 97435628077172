import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import * as Store from '../store/Store';

import { TextField, Grid, Button, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';


const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: '#fff',
    },
});
interface Match {
    id: string | undefined;
}

type StatusProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<Match>;


const Status = (props: StatusProps) => {
    const [status, setStatus] = useState<Store.Status>({
        ID: 0,
        Code: '',
        Denomination: ''
    });
    useEffect(() => {
        if (props.match.params.id) {
            var id: number = parseInt(props.match.params.id);
            if (id) {
                props.requestStatus(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.status) {
            setStatus({ ...props.status });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingSave, props.isLoading]);



    const onSave = () => {
        if (status !== null) {
            if (status.ID > 0) {
                props.updateStatus(status);
            }
            else {
                props.createStatus(status);
            }
        }
    }
    const onBack = () => {
        props.history.push(`/Statuses`);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setStatus({ ...status, [key]: newValue });
    }
    return <div>
        {!props.isLoading && <Grid container spacing={2} className={props.classes.root}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Code" value={status.Code} id="Code" onChange={onChange("Code")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><TextField fullWidth label="Denomination" value={status.Denomination} id="Denomination" onChange={onChange("Denomination")} /></Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={2}><Button fullWidth color="primary" variant="contained" onClick={onSave}>SALVA</Button></Grid>
            <Grid item xs={2}><Button fullWidth variant="contained" onClick={onBack}>INDIETRO</Button></Grid>
            <Grid item xs={4}></Grid>

        </Grid>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;

}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(Status) as any);