import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as Store from '../store/Store';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

type StatusProps =
    Store.StoreState &
    typeof Store.actionCreators &
    RouteComponentProps<{}>;

interface IRowData {
    dataIndex: number;
    index: number;
}
interface IRow {
    data: IRowData[];
}

const Statuses = (props: StatusProps) => {
    useEffect(() => {
        props.requestStatusList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.requestStatusList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDelete]);
    const onEditStatus = (id: number) => () => {
        props.history.push(`/status/edit/${id}`);
    }

    const onNewStatus = () => {
        props.history.push(`/status/create`);
    }
    const onDeleteStatus = (row: IRow) => () => {
        var { statuses } = props;
        for (var i in row.data) {
            var data: IRowData = row.data[i];
            var status: Store.Status = statuses[data.dataIndex];
            props.deleteStatus(status.ID);
        }
    }
    const columns: MUIDataTableColumnDef[] = [
        { name: "Code", label: 'Codice' },
        { name: "Denomination", label: 'Denominazione' },
    ];
    const customToolbar = () => {
        return <React.Fragment>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateStatuses || false)) && <Tooltip title="Nuovo">
                <IconButton onClick={onNewStatus}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </React.Fragment>;
    }
    const customToolbarSelect = (selectedRows: IRow) => {
        var { statuses } = props;
        var data: IRowData = selectedRows.data[0];
        var status: Store.Status = statuses[data.dataIndex];
        return <div>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateStatuses || false)) && selectedRows.data.length === 1 && <Tooltip title="Modifica">
                <IconButton onClick={onEditStatus(status.ID)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.DeleteStatuses || false)) && <Tooltip title="Elimina">
                <IconButton onClick={onDeleteStatus(selectedRows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>}
        </div>;
    }

    if (props.isLoading) {
        return <div>Loading</div>;
    }

    return <div>
        <MUIDataTable
            title="Stati"
            data={props.statuses}
            columns={columns}
            options={{
                filterType: "checkbox",
                download: false,
                print: false,
                viewColumns: false,
                filter: false,
                selectableRowsHeader: props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateStatuses || false) || (props.CurrentUser?.Permissions.DeleteStatuses || false),
                isRowSelectable: () => props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateStatuses || false) || (props.CurrentUser?.Permissions.DeleteStatuses || false),
                customToolbar: customToolbar,
                customToolbarSelect: customToolbarSelect
            }}
        />
    </div>;

}

export default connect(
    (state: ApplicationState) => state.store,
    Store.actionCreators
)(Statuses as any);
