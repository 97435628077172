import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index'
import { format } from 'date-fns';
import * as Store from '../store/Store';

import { TextField, Grid, Button, createStyles, withStyles, WithStyles, Theme, Select, MenuItem } from '@material-ui/core';


const styles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        background: '#fff',
    },
});
interface Match {
    id: string | undefined;
    FIDSoftware: string;
}

type SoftwareVersionProps =
    Store.StoreState &
    typeof Store.actionCreators &
    WithStyles<typeof styles> &
    RouteComponentProps<Match>;


const SoftwareVersion = (props: SoftwareVersionProps) => {
    const [softwareVersion, setSoftwareVersion] = useState<Store.SoftwareVersion>({
        ID: 0,
        FIDSoftware: parseInt(props.match.params.FIDSoftware),
        Version: '',
        ReleaseNotes: '',
        ReleaseDate: new Date(),
        DismissDate: null,
        FIDStatus: 0
    });
    useEffect(() => {
        if (props.match.params.id) {
            var id: number = parseInt(props.match.params.id);
            if (id) {
                props.requestSoftwareVersion(id);
            }
        }
        props.requestStatusList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.softwareVersion) {
            setSoftwareVersion({ ...props.softwareVersion });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingSave, props.isLoading]);



    const onSave = () => {
        if (softwareVersion !== null) {
            if (softwareVersion.ID > 0) {
                props.updateSoftwareVersion(softwareVersion);
            }
            else {
                props.createSoftwareVersion(softwareVersion);
            }
        }
    }
    const onBack = () => {
        props.history.push(`/SoftwareVersions/${props.match.params.FIDSoftware}`);
    }
    const onChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setSoftwareVersion({ ...softwareVersion, [key]: newValue });
    }
    const onSelectChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        setSoftwareVersion({ ...softwareVersion, [key]: event.target.value });
    }
    return <div>
        {!props.isLoading && <Grid container spacing={2} className={props.classes.root}>
            <Grid container item xs={4}></Grid>
            <Grid container item xs={4} spacing={2}>
                <Grid item xs={12}><TextField fullWidth label="Versione" value={softwareVersion.Version} id="Version" onChange={onChange("Version")} /></Grid>
                <Grid item xs={12}><TextField fullWidth label="Note rilascio" value={softwareVersion.ReleaseNotes} id="ReleaseNotes" onChange={onChange("ReleaseNotes")} /></Grid>
                <Grid item xs={12}><TextField fullWidth label="Data rilascio" value={format(softwareVersion.ReleaseDate,"yyyy-MM-dd")} id="ReleaseDate" onChange={onChange("ReleaseDate")} type="date" /></Grid>
                <Grid item xs={12}><TextField fullWidth label="Data dismessa" value={softwareVersion.DismissDate ? format(softwareVersion.DismissDate,"yyyy-MM-dd") : ""} id="DismissDate" onChange={onChange("DismissDate")} type="date" /></Grid>
                <Grid item xs={12}>
                    <Select fullWidth label="Stato" id="FIDStatus" value={softwareVersion.FIDStatus} onChange={onSelectChange("FIDStatus")}>
                        {props.statuses.map(item => <MenuItem key={item.ID} value={item.ID}>{item.Code} - {item.Denomination}</MenuItem>)}
                    </Select>
                </Grid>

                <Grid item xs={6}><Button fullWidth color="primary" variant="contained" onClick={onSave}>SALVA</Button></Grid>
                <Grid item xs={6}><Button fullWidth variant="contained" onClick={onBack}>INDIETRO</Button></Grid>
            </Grid>
            <Grid container item xs={4}></Grid>
        </Grid>}
        {props.isLoading && <span>CARICAMENTO</span>}
    </div>;

}

var enhance = compose(
    connect(
        (state: ApplicationState) => state.store,
        Store.actionCreators
    ),
)
export default enhance(withStyles(styles)(SoftwareVersion) as any);