import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as Store from '../store/Store';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

type SoftwareProps =
    Store.StoreState &
    typeof Store.actionCreators &
    RouteComponentProps<{}>;

interface IRowData {
    dataIndex: number;
    index: number;
}
interface IRow {
    data: IRowData[];
}


const columns: MUIDataTableColumnDef[] = [
    { name: "Code", label: 'Codice' },
    { name: "Denomination", label: 'Denominazione' },
    
];
const Softwares = (props: SoftwareProps) => {
    useEffect(() => {
        props.requestSoftwareList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.requestSoftwareList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDelete]);
    const onEditSoftware = (id: number) => () => {
        props.history.push(`/software/edit/${id}`);
    }
    const onNewSoftware = () => {
        props.history.push(`/software/create`);
    }
    const onDeleteSoftware = (row: IRow) => () => {
        var { softwares } = props;
        for (var i in row.data) {
            var data: IRowData = row.data[i];
            var software: Store.Software = softwares[data.dataIndex];
            props.deleteSoftware(software.ID);
        }
    }
    const customToolbar = () => {
        return <React.Fragment>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwares || false)) && <Tooltip title="Nuovo">
                <IconButton onClick={onNewSoftware}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </React.Fragment>;
    }
    const customToolbarSelect = (selectedRows: IRow) => {
        var { softwares } = props;
        var data: IRowData = selectedRows.data[0];
        var software: Store.Software = softwares[data.dataIndex];
        return <div>
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwares || false)) && selectedRows.data.length === 1 && <Tooltip title="Modifica">
                <IconButton onClick={onEditSoftware(software.ID)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {(props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.DeleteSoftwares || false)) && <Tooltip title="Elimina">
                <IconButton onClick={onDeleteSoftware(selectedRows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>}
        </div>;
    }

    if (props.isLoading) {
        return <div>Loading</div>;
    }

    return <div>
        <MUIDataTable
            title="Softwares"
            data={props.softwares}
            columns={columns}
            options={{
                filterType: "checkbox",
                download: false,
                onRowsDelete: onDeleteSoftware,
                print: false,
                viewColumns: false,
                filter: false,
                selectableRowsHeader: props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwares || false) || (props.CurrentUser?.Permissions.DeleteSoftwares || false),
                isRowSelectable: () => props.CurrentUser?.IsAdmin || (props.CurrentUser?.Permissions.CreateSoftwares || false) || (props.CurrentUser?.Permissions.DeleteSoftwares || false),
                customToolbar: customToolbar,
                customToolbarSelect: customToolbarSelect
            }}
        />
    </div>;
}

export default connect(
    (state: ApplicationState) => state.store,
    Store.actionCreators
)(Softwares as any);
